import React from 'react'

export default function NotFound() {
  return (
    <>
      <div>
        <h1>404: TIDAK ADA</h1>
        <p>Halaman ini tidak ada... </p>
      </div>
    </>
  )
}
